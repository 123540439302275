import React, { Component } from 'react';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';

class MoneySavingTools extends Component {
    state = {}
    render() {
        return (
            <div>
                <HeaderMain />
                <section className="content-container">
                <div className="container m-b-30">
                    <h1 className="m-b-30">$$ Saving Tools</h1>
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/settings-color.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">CYA (Cover Your @ss)</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/engineer.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Find ReloHelpers</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/box.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Buy Boxes & Packing Supplies</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/tape.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Buy Moving Equipment</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/delivery-truck2.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Rent a Truck</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/hotel.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Book a Hotel</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/ruler.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Find a Scale</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/gas-pump.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Find Cheap Fuel</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/area.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Find Truck Parking</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/work-tools.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Roadside Service</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/office-material.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Rent Self Storage Unit</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/houseSearch.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Find a Home</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/house.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Sell/ Rent Your House</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/home.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Fix Up My House</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/discount.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Travel Coupons & Discounts</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/hatchback.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Rent a Car</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/plane.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Book a Flight</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/ship1.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Book a Cruise</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#">
                            <div className="relo-box">
                                <div className="media">
                                    <div className="media-left">
                                        <figure className=""><img src="assets/images/icons/hammock.svg" alt="" width="60"/></figure>
                                    </div>
                                    <div className="media-body">
                                        <h5 className="m-0">Vacation Packages</h5>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
                <FooterMain />
            </div >
        );
    }
}

export default MoneySavingTools;