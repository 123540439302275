import React, { Component } from 'react';
import { Route, Redirect, HashRouter } from 'react-router-dom';
import Storage from './externaljs/Storage';
import Urls from './externaljs/Urls';

import Login from './components/Login';
import Homepage from './components/Homepage';
// import RegistrationSelectProfile from './components/RegistrationSelectProfile';
// import RegistrationClient from './components/RegistrationClient';
import RegistrationMmc from './components/SignUp';
import Wwa from './components/Wwa';
import GetMilApv from './components/GetMilApv';
// import RegistrationIndividual from './components/RegistrationIndividual';
import ForgotPassword from './components/ForgotPassword';
import OtpCheck from './components/OtpCheck';
import RegisterStepTwo from './components/RegisterStepTwo';
import ContactUs from './components/ContactUs';

import PrivacyPolicy from './components/PrivacyPolicy';
import TermsofService from './components/TermsofService';
import SubscriptionAgreement from './components/SubscriptionAgreement';
import MoneySavingTools from './components/MoneySavingTools';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <div className="App">
          <Route path="/" exact render={
            () => { return <Redirect to="/login" /> }
          } />

          <Route path="/home" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <Homepage {...props} />
              }
            }
          } />
          <Route path="/wwa" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <Wwa {...props} />
              }
            }
          } />
          <Route path="/getmilapv" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <GetMilApv {...props} />
              }
            }
          } />

          <Route path="/privacy-policy" render = {
            (props) => {
              if(Storage.get('myPcsGovLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <PrivacyPolicy {...props}/>
              }
            }
          }/> 

          <Route path="/subscription-agreement" render = {
            (props) => {
              if(Storage.get('myPcsGovLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <SubscriptionAgreement {...props}/>
              }
            }
          }/>

          <Route path="/terms-service" render = {
            (props) => {
              if(Storage.get('myPcsGovLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <TermsofService {...props}/>
              }
            }
          }/>

          <Route path="/money-saving-tools" render = {
            (props) => {
              if(Storage.get('myPcsGovLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <MoneySavingTools {...props}/>
              }
            }
          }/>     

          <Route path="/contact-us" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <ContactUs {...props} />
              }
            }
          } />

          {/* <Route path="/registration-select-profile" render = {
            (props) => {
              if(Storage.get('myPcsGovLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <RegistrationSelectProfile {...props}/>
              }
            }
          }/>

           <Route path="/registration-client" render = {
            (props) => {
              if(Storage.get('myPcsGovLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <RegistrationClient {...props}/>
              }
            }
          }/> */}

          <Route path="/registration-mmc" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <RegistrationMmc {...props} />
              }
            }
          } />

          <Route path="/registration-mmc-step-two" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <RegisterStepTwo {...props} />
              }
            }
          } />

          {/* <Route path="/registration-individual" render = {
            (props) => {
              if(Storage.get('myPcsGovLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <RegistrationIndividual {...props}/>
              }
            }
          }/> */}

          <Route path="/otp-check" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <OtpCheck {...props} />
              }
            }
          } />

          <Route path="/forgot-password" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <ForgotPassword {...props} />
              }
            }
          } />


          {/* <Route path="/login" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') !== '') {
                if((JSON.parse(Storage.get('myPcsGovLoginData'))).profile_id==8)
                  return <Redirect to="/client/dashboard" />
                else if((JSON.parse(Storage.get('myPcsGovLoginData'))).profile_id==2)
                  return <Redirect to="/moving-company/dashboard" />   
                  else if((JSON.parse(Storage.get('myPcsGovLoginData'))).profile_id==4)
                  return <Redirect to="/customer/dashboard" />        
                } else {
                return <Login {...props} />
              }
            }
          } /> */}

          {/* <Route path="/login" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') !== '') {
                  if ((JSON.parse(Storage.get('myPcsGovLoginData'))).profile_id == 2){
                    window.location.href=Urls.mmcDashboard
                    return <p></p>;
                  } else if ((JSON.parse(Storage.get('myPcsGovLoginData'))).profile_id == 8){
                    window.location.href=Urls.clientDashboard
                    return <p></p>;
                  } else if ((JSON.parse(Storage.get('myPcsGovLoginData'))).profile_id == 4){
                    window.location.href=Urls.customerDashboard
                    return <p></p>;
                  }  else {
                    return <p></p>;
                  }                 
              } else {
                return <Login {...props} />
              }
            }
          } /> */}

          <Route path="/login" render={
            (props) => {
              if (Storage.get('myPcsGovLoginToken') != '') {
                if ((JSON.parse(Storage.get('myPcsGovLoginData'))).profile_id == 10) {
                  window.location.href = Urls.mmcDashboard
                  return <p></p>;
                } else if ((JSON.parse(Storage.get('myPcsGovLoginData'))).profile_id == 7) {
                  window.location.href = Urls.mmcDashboard
                  return <p></p>;
                } else {
                  return <p></p>;
                }
              } else {
                return <Login {...props} />
              }
            }
          } />

        </div>
      </HashRouter>
    );
  }
}

export default App;
