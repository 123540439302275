export function FormGroupMaterial() {
    // Variables
    var onClass = "on";
    var showClass = "is-visible";


    // Setup
    window.$("input:not(.token-input):not(.bootstrap-tagsinput > input), textarea, select").on("checkval change", function () {

        // Define label
        var label = window.$(this).parents('.form-group-material').children(".control-label");

        // Toggle label
        if (this.value !== "") {
            label.addClass(showClass);
        }
        else {
            label.removeClass(showClass).addClass('animate');
        }

    }).on("keyup", function () {
        window.$(this).trigger("checkval");
    }).trigger("checkval").trigger('change');


    // Remove animation on page load
    window.$(window).on('load', function() {
        window.$(".form-group-material").children('.control-label.is-visible').removeClass('animate');
    });
}