import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class HeaderMain extends Component {
    toggleNavbar = () => {
        window.$('body').toggleClass('show-menu');
    }
    state = {}

    render() {

        return (
            <header className="relotech-header">
                <div className="container">
                    <Link className="common-header-logo" to="/home"><img src="assets/images/MyPCSGov-logo-black.png" alt="Logo" /></Link>
                    <ul className="navbar">
                        <li className="active"><Link to="/home" onClick={this.toggleNavbar}>home</Link></li>
                        <li><Link to="/wwa" onClick={this.toggleNavbar}>Who We Are</Link></li>
                        <li><Link to="/getmilapv" onClick={this.toggleNavbar}>get military approved</Link></li>
                        <li><Link to="/money-saving-tools" onClick={this.toggleNavbar}>$$ Saving Tools</Link></li>
                        <li><Link to="/contact-us" onClick={this.toggleNavbar}>contact us</Link></li>
                        <li><Link to="/login" className="header-login-btn" onClick={this.toggleNavbar}>Login</Link></li>

                    </ul>
                    <span className="navbar-toggle" onClick={this.toggleNavbar}></span>
                </div>
            </header>
        );
    }
}

export default HeaderMain;