import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';
export default function PostDataWithParam(url,params){

    let headers = new Headers();
    headers.append('Content-type','application/json');      
    headers.append('token',Storage.get('myPcsGovLoginToken'));
  

    return  fetch(Urls.api+url,{
            method:'POST',
            headers:headers,
            body:JSON.stringify(params)
        })
        .then( res => res.json())
}