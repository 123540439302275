import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import PostDataWithParam from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';
import { FormGroupMaterial } from '../externaljs/FormGroupMaterial';

class Login extends Component {
    state = {
        email: '',
        password: '',
        profile_id: 10,
        loggedIn: false,
        emailErr: '',
        passwordErr: '',
        loginErr: '',
        regSuc: '',
        processing: false,
        urlToken:''
    }

    componentDidMount() {
        if(!!this.props.location.search)
        {
            let queryParams=new URLSearchParams(this.props.location.search);
            if(queryParams!=='')
            {
                let urlToken=queryParams.get("token");
                if(!!urlToken)
                {
                    this.setState({ urlToken: urlToken },()=>
                    {
                       this.handleActivationLogin();
                    });
                }
              
            }
        }
        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ regSuc: this.props.location.state.regErr, loginErr: '' });
        }
        window.$(window).on('load', function () {
            window.$('#onLoadModal').modal('show');
        });
        FormGroupMaterial();
    }

    componentWillUnmount() {
        this.setState({ regSuc: '' });
        this.setState({ loginErr: '' });
    }


    changeValue = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleActivationLogin= (e) => {
        this.setState({ processing: true }, () => {
            PostDataWithParam('AuthenticateDetails', { 'token': this.state.urlToken })
                .then((response) => {
                    let result = response;
                    if (result.success === 1) {
                        Storage.set('myPcsGovLoginToken', result.token);
                        Storage.set('myPcsGovLoginData', JSON.stringify(result.data));
                        // Storage.set('mmcFirstLogin', 0);
                        this.setState({ loggedIn: true });
                        this.getCustomProfileMenu();
                    } else {
                        this.setState({ loginErr: result.msg, regSuc: '', loggedIn: false, processing: false });
                    }
                })
                .catch(err => {
                    this.setState({ loginErr: 'Oops! Something went wrong.', regSuc: '', loggedIn: false, processing: false });
                })
         })
    }
    handleLogin = (e) => {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({ emailErr: 'Email is required.' });
        }
        else if (!this.state.password) {
            this.setState({ emailErr: '' });
            this.setState({ passwordErr: 'Password is required.' });
        } else {
            this.setState({ emailErr: '' });
            this.setState({ passwordErr: '' });

            this.setState({ processing: true }, () => {
                PostDataWithParam('Authenticate', { email: this.state.email, password: this.state.password, profile_id: 10 })
                    .then((response) => {
                        // return
                        let result = response;
                        if (result.success === 1) {
                            Storage.set('myPcsGovLoginToken', result.token);
                            Storage.set('myPcsGovLoginData', JSON.stringify(result.data));
                            // Storage.set('mmcFirstLogin', 0);
                            this.setState({ loggedIn: true });
                            this.getCustomProfileMenu();
                        } else {
                            this.setState({ loginErr: result.msg, regSuc: '', loggedIn: false, processing: false });
                        }
                    })
                    .catch(err => {
                        this.setState({ loginErr: 'Oops! Something went wrong.', regSuc: '', loggedIn: false, processing: false });
                    })
            })
        }
    }

    getCustomProfileMenu() {
        let custom_profile_id = (JSON.parse(Storage.get('myPcsGovLoginData'))).custom_profile_id;
        this.setState({ custom_profile_id: custom_profile_id }, () => {
            // console.log(this.state.custom_profile_id);
        });
        if (custom_profile_id != "") {
            try {
                this.state.isLoadCustomMenu = false;
                let response = PostDataWithParam('GetCustomProfileWiseMenuList', { custom_profile_id: custom_profile_id })

                if (response.success == 1) {
                    // let custom_menu = [];
                    let custom_menu = {
                        Messages: null,
                        "Manage Relocations": null,
                        "Manage Claims": null,
                        "DOD Precheck":null,
                        "Find a Pro": null,
                        USTRANSCOM: null,
                        "Customer Satisfaction Survey": null,
                        Settings: null
                    };
                    for (let j = 0; j < response.data.length; j++) {
                        custom_menu[response.data[j].title] = response.data[j];
                    }
                    Storage.set('myPcsGovStaffMenu', JSON.stringify(Object.values(custom_menu).filter(menu => !!menu)));
                    this.setState({ isLoadCustomMenu: true });

                } else {
                    // await this.setState({
                    //     custom_menu: [],
                    // })
                }
            } catch (err) {
                // await this.setState({
                //     custom_menu: [],
                // })
            }
        }
        else {
            this.setState({ isLoadCustomMenu: true });
        }
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true} />
        } else {
            return <button type="submit" className="btn btn-primary pull-left">LOGIN</button>
        }
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-danger"><i className="icon-cross position-left"></i>
                    {this.state.loginErr}
                </div>
            )
        }
    }

    showSuccessAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-success"><i className="icon-check2 position-left"></i>
                    {this.state.regSuc}
                </div>
            )
        }
    }

    render() {

        if (this.state.loggedIn) {
            if ((JSON.parse(Storage.get('myPcsGovLoginData'))).profile_id == 10) {
                window.location.href = Urls.mmcDashboard
                return <p></p>;
            }
            // else if ((JSON.parse(Storage.get('myPcsGovLoginData'))).profile_id == 7) {
            //     window.location.href = Urls.mmcDashboard
            //     return <p></p>;
            // }
        }

        return (

            <React.Fragment>

                <div id="onLoadModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">DoD Security Banner</h4>
                            </div>
                            <div className="modal-body">
                                <p>YOU ARE ACCESSING A U.S. GOVERNMENT (USG) INFORMATION SYSTEM (IS) THAT IS
                                    PROVIDED FOR USG-AUTHORIZED USE ONLY.</p>
                                <p>By using this IS (which includes any device attached to this IS), you consent to the
                                    following conditions:</p>
                                <ul>
                                    <li>
                                        The USG routinely intercepts and monitors communications on this IS for purposes
                                        including,but not limited to,penetration testing,COMSEC monitoring, network
                                        operations and defense, personnel misconduct (PM),law enforcement (LE), and
                                        counterintelligence (CI) investigations.
                                    </li>
                                    <li>
                                        At any time, the USG may inspect and seize data stored on this IS.
                                    </li>
                                    <li>Communications using,or data stored on, this IS are not private, are subject to
                                        routine monitoring, interception, and search, and may be disclosed or used for any
                                        USG-authorized purpose.
                                    </li>
                                    <li>
                                        This IS includes security measures (e.g., authentication and access controls) to
                                        protect USG interests--not for your personal benefit or privacy.
                                    </li>
                                    <li>
                                        Notwithstanding the above, using this IS does not constitute consent to PM, LE or
                                        CI investigative searching or monitoring of the content of privileged communications,
                                        or work product, related to personal representation or services by attorneys,
                                        psychotherapists, or clergy, and their assistants. Such communications and work
                                        product are private and confidential. See User Agreement for details.
                                    </li>
                                </ul>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-xs" data-dismiss="modal">Accept</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login-page-register">
                    <div className="login-register-box">
                        <div className="text-center login-box-header">
                            <div className="m-b-30 logo">
                                <Link to="/home">
                                    <img src="assets/images/MyPCSGov-logo-black.png" alt="site-logo" />
                                </Link>
                            </div>
                            <div className="btn-group">
                                <Link to="/login" className="btn border-slate text-slate-800 btn-flat text-uppercase active">Sign in</Link>
                                <Link to="/registration-mmc" className="btn border-slate text-slate-800 btn-flat text-uppercase">Create Free Account
                            <p className="m-0 f-s-13">(No Credit Card Required)</p></Link>
                            </div>
                            <br />
                            {this.showAlert(this.state.loginErr)}
                            {this.showSuccessAlert(this.state.regSuc)}
                        </div>
                        <form action="#" method="POST" onSubmit={this.handleLogin}>
                            <div className="login-box-body m-t-30">
                                <div className="form-group form-group-material">
                                    <label className="control-label">Email</label>
                                    <input type="email" className="form-control" placeholder="Email" name="email" onChange={this.changeValue} required />
                                    <p className="text-danger help-block">{this.state.emailErr}</p>
                                </div>
                                <div className="form-group form-group-material">
                                    <label className="control-label">Password</label>
                                    <input type="password" className="form-control" placeholder="Password" name="password" onChange={this.changeValue} required />
                                    <p className="text-danger help-block">{this.state.passwordErr}</p>
                                </div>
                                <div className="form-group m-t-40 clearfix">
                                    {this.renderSubmitBtn()}&nbsp;
                                <Link to="/forgot-password" className="btn btn-link pull-right m-t-8">Forgot Password?</Link>
                                </div>
                            </div>
                            <hr className="m-t-40 m-b-40" />
                            <div className="login-box-footer text-center">
                                <span className="text-grey-400"> Don&rsquo;t have an account?</span>
                                <Link to="/registration-mmc" className="link-btn">Sign Up Now</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Login;