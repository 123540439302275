// export default {
//     login: 'http://localhost:3016/#/login',
//     mmcDashboard:'http://localhost:3017/#/',
//     api: "https://myrelo.us/stagingRelotechApi/public/Api/",
//     paypal: {
//         env: 'sandbox',  // production
//         currency: 'USD',
//         client: {
//             sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
//             production: 'YOUR-PRODUCTION-APP-ID',
//         }
//     }
// }

// export default {
//     login: 'https://relotech.us/dev/',
//     mmcDashboard:'https://relotech.us/dev/move_management_company/',
//     paypal: {
//         env: 'production',  // sandbox
//         currency: 'USD',
//         client: {
//             sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
//             production: 'AU6GAzqguPcD0CCcciIz2GdSWHy0GlR7P4-Z5mRkWtVE6xYq5QmdV0HlKii27IKFmhNmkHHK-b6IHRVQ',
//         }
//     }
// }

// for live server
// export default {
//     login: 'https://mypcsgov.com/',
//     mmcDashboard:'https://mypcsgov.com/qc/',
//     api: "https://myrelo.us/relotechApi/public/Api/",
//     paypal: {
//         env: 'production',  // sandbox
//         currency: 'USD',
//         client: {
//             sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
//             production: 'AU6GAzqguPcD0CCcciIz2GdSWHy0GlR7P4-Z5mRkWtVE6xYq5QmdV0HlKii27IKFmhNmkHHK-b6IHRVQ',
//         }
//     }
// }


// for staging server
export default {
    login: 'https://staging.mypcsgov.com/',
    mmcDashboard:'https://staging.mypcsgov.com/qc/',
    api: "https://myrelo.us/stagingRelotechApi/public/Api/",
    paypal: {
        env: 'production',  // sandbox
        currency: 'USD',
        client: {
            sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
            production: 'AU6GAzqguPcD0CCcciIz2GdSWHy0GlR7P4-Z5mRkWtVE6xYq5QmdV0HlKii27IKFmhNmkHHK-b6IHRVQ',
        }
    }
}