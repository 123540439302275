import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';
import PostDataWithParam from '../services/PostDataWithParam'

class SignUp extends Component {
    state = {
        fields: {
            contact_no: '',
            email: '',
            organization_name: '',
            password: '',
            profile_id: 2,
            card_name: '',
            card_no: '',
            expiry_month: '',
            expiry_year: '',
            cvv_no: '',
            amount: '',
            company_contact_no: '',
            first_name: '',
            last_name: '',
            subscription_id: 1,
            website_link: '',
            position: '',
            support_email: 'help@myrelo.us',
            url: 'GetMilitaryApproved.com'
        },
        error: {
            confPasswordErr: '',
            dobErr: '',
            urlErr: ''
        },
        processing: false,
        regStatus: false,
        regErr: ''
    }

    componentDidMount() {     
        if (this.props.location.state && typeof this.props.location.state.amount != 'undefined' && typeof this.props.location.state.subscription_id != 'undefined') {
            if (typeof this.props.location.state.email == 'undefined') {
                let fields = Object.assign({}, this.state.fields);
                fields.amount = this.props.location.state.amount;
                fields.subscription_id = this.props.location.state.subscription_id;

                this.setState({ fields: fields }, () => {

                })
            } else {
                this.setState({ fields: this.props.location.state }, () => {
                });
            }
        }
        this.setState({ regErr: '' });
    }

   


    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if( e.target.name=='company_contact_no' ||  e.target.name=='contact_no')
        {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name]= match[1] + ' ' + match[2] + ' ' + match[3]
              }
        }
        
        this.setState({ fields: fields });
    }

    initAutocomplete = (e) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = place.formatted_address;
            this.setState({ fields: fields });

        })
    }

    handleAddMmc = (e) => {
        e.preventDefault();
        let flag = 1;

        let error = this.state.error;
        if(this.state.fields.website_link!='')
        {
            var inputurl=this.state.fields.website_link;
            
            var res = inputurl.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm);
            if(res == null)
            {
                error.urlErr = 'Please Enter a Valid Url.';
                flag = 0;
                this.setState({ error: error });
            }
        }
        else if (this.state.fields.password !== this.state.fields.confirm_password) {
            error.urlErr = '';
            error.confPasswordErr = 'Password & Confirm password does not match.';
            flag = 0;
            this.setState({ error: error });
        }        
        if(flag == 1){
            error.urlErr = '';
            error.confPasswordErr = '';
            this.setState({ error: error });
            if (this.state.fields.subscription_id == 1) {
                this.setState({ processing: true }, () => {
                    PostDataWithParam('UserRegistration', this.state.fields)
                        .then((response) => {
                            if (response.success == 1) {
                                this.setState({ regErr: response.msg, regStatus: true });
                            } else {
                                this.setState({ regErr: response.msg, processing: false, regStatus: false });
                            }
                        })
                        .catch(err => {
                            this.setState({ regErr: 'Oops! Something went wrong.', processing: false, regStatus: false });
                        })
                })
            } else {
                this.setState({ processing: true }, () => {
                    PostDataWithParam('DuplicateEmailCheck', {email_id: this.state.fields.email})
                    .then((response) => {
                        if (response.success == 1) {
                            this.setState({ processing: false }, () => {
                                this.props.history.push({
                                    pathname: '/registration-mmc-step-two',
                                    state: this.state.fields
                                })
                            });
                        } else {
                            this.setState({ regErr: response.msg, processing: false, regStatus: false });
                        }
                    })
                    .catch(err => {
                        this.setState({ regErr: 'Oops! Something went wrong.', processing: false, regStatus: false });
                    })
                });
            }
        }
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true} />
        } else {
            if (this.state.fields.subscription_id == 1)
                return <button type="submit" className="btn btn-primary">REGISTER</button>
            else
                return <button type="submit" className="btn btn-primary">CONTINUE</button>
        }
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-danger"><i className="icon-cross position-left"></i>
                    {this.state.regErr}
                </div>
            )
        }
    }

    render() {
        if (this.state.regStatus) {
            return <Redirect to={{
                pathname: '/login',
                state: { regErr: this.state.regErr }
            }} />
        }

        return (
            <div className="login-page-register register-page">
                <div className="login-register-box">
                    <div className="text-center login-box-header">
                        <div className="m-b-40">
                            <img src="assets/images/relotech-logo.png" alt="site-logo" />
                        </div>
                        <div className="btn-group">
                            <Link to="/login" className="btn border-slate text-slate-800 btn-flat text-uppercase ">Sign in</Link>
                            <Link to="/registration-mmc" className="btn border-slate text-slate-800 btn-flat text-uppercase active">Create Free Account
                            <p className="m-0 f-s-13">(No Credit Card Required)</p></Link>
                        </div>
                        <br />
                        {this.showAlert(this.state.regErr)}
                    </div>
                    <form action="#" method="POST" onSubmit={this.handleAddMmc} >
                        <div className="login-box-body m-t-30">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">First Name</label>
                                        <input type="text" name="first_name" value={this.state.fields.first_name} className="form-control" placeholder="First Name" onChange={this.changeValue} required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Last Name</label>
                                        <input type="text" name="last_name" value={this.state.fields.last_name} className="form-control" placeholder="Last Name" onChange={this.changeValue} required />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Position</label>
                                        <input type="text" name="position" value={this.state.fields.position} className="form-control" placeholder="Position" onChange={this.changeValue} required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Direct Phone Number</label>
                                        <input type="text" name="company_contact_no" value={this.state.fields.company_contact_no} className="form-control" placeholder="Direct Phone Number" onChange={this.changeValue} required maxLength="10"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Company Name</label>
                                        <input type="text" name="organization_name" value={this.state.fields.organization_name} className="form-control" placeholder="Company Name" onChange={this.changeValue} required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Main Phone Number</label>
                                        <input type="text" name="contact_no" value={this.state.fields.contact_no} className="form-control" placeholder="Main Phone Number" onChange={this.changeValue} required maxLength="10"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Website URL (if any)</label>
                                        <input type="text" name="website_link" value={this.state.fields.website_link} className="form-control" placeholder="Website URL (if any)" onChange={this.changeValue} />
                                        <p className="text-danger help-block">{this.state.error.urlErr}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Email Address</label>
                                        <input type="email" name="email" value={this.state.fields.email} className="form-control" placeholder="Email" onChange={this.changeValue} required />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Password</label>
                                        <input type="password" name="password" value={this.state.fields.password} className="form-control" placeholder="Password" onChange={this.changeValue} required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Password Confirmation</label>
                                        <input type="password" name="confirm_password" value={this.state.fields.confirm_password} className="form-control" placeholder="Confirm Password" onChange={this.changeValue} required />
                                        <p className="text-danger help-block">{this.state.error.confPasswordErr}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group m-t-40 m-b-10 clearfix text-center">
                                {this.renderSubmitBtn()}
                            </div>
                            <p className="text-center text-grey-400 f-s-12">By clicking <strong>REGISTER</strong> you agree to our <a href="#" className="text-underline f-s-15">terms and conditions</a></p>
                        </div>
                        <hr className="m-t-40 m-b-40" />
                        <div className="login-box-footer text-center">
                            <span className="text-grey-400"> Already have an account?</span>
                            <Link to="/login" className="link-btn">Sign In</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default SignUp;