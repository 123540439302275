import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';
export default function GetData(url){

    let headers = new Headers();
    headers.append('Content-type','application/json');        
    headers.append('token',Storage.get('myPcsGovLoginToken'));

    return  fetch(Urls.api+url,{
            method:'GET',
            headers:headers
        })
        .then( res => res.json())
}

