import React, { Component } from 'react'
import { Link} from 'react-router-dom';

class FooterMain extends Component {
    state = {}
    handleScrollToStats = () => {
        window.scrollTo({
            top: 0
        })
   }

    render() {
        return (
            <footer className="footer-wrap p-t-40 p-b-20">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-3">
                            <div className="common-footer-logo">
                                <Link to="/home"><img src="assets/images/MyPCSGov-logo-white.png" /></Link>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h6 className="text-white m-t-0">Company</h6>
                            <ul className="p-0">
                                <li><Link to="/wwa" onClick={this.handleScrollToStats}>Who We Are</Link></li>
                                <li><Link to="/terms-service" onClick={this.handleScrollToStats}>Terms of Service</Link></li>
                                <li><Link to="/privacy-policy" onClick={this.handleScrollToStats}>Privacy Policy</Link></li>
                                <li><Link to="/subscription-agreement" onClick={this.handleScrollToStats}>Subscription Agreement</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h6 className="text-white m-t-0">Resources</h6>
                            <ul className="p-0">
                                <li><Link to="/contact-us" onClick={this.handleScrollToStats}>Contact us</Link></li>
                                <li><Link to="/subscription-agreement" onClick={this.handleScrollToStats}>Pricing</Link></li>
                                <li><Link to="/contact-us" onClick={this.handleScrollToStats}>FAQ</Link></li>                                
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h6 className="text-white m-t-0">Resources</h6>
                            <ul className="p-0 app">
                                <li><Link to="/contact-us"><img src="front-assets/images/Apple_AppStore.png" alt="" /></Link></li>
                                <li><Link to="/contact-us"><img src="front-assets/images/Google_PlayStore.png" alt="" /></Link></li>
                            </ul>
                        </div>
                        <div className="col-md-12 text-center p-t-20">
                            <p>&copy; 2019 GetMilitary Approved Inc. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterMain;
