import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import PostDataWithParam from '../services/PostDataWithParam';
import { FormGroupMaterial } from '../externaljs/FormGroupMaterial';

class ForgotPassword extends Component {
    state = {
        profile_id: 10,
        email: '',
        EmailStatus: false,
        emailErr: '',
        loginErr: '',
        regSuc:'',
        processing: false
    }

    componentDidMount() {        
        FormGroupMaterial();
    }

    componentWillUnmount() {
        this.setState({ regSuc: '',loginErr: '',emailErr:'' });
    }


    changeValue = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleForgotPassword = (e) => {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({ emailErr: 'Email is required.' });
        } 
        else {
            this.setState({ emailErr: '' });
            this.setState({processing: true},()=>{
            PostDataWithParam('VerifyEmail', this.state)
                .then((response) => {
                    if (response.success === 1) {
                        window.swal('Success!', response.msg, 'success')
                        this.setState({ EmailStatus: true });
                    } else {
                        window.swal('Error!', response.msg, 'error')
                    }
                    this.setState({processing: false});
                })
                .catch(err => {
                    window.swal('Error!', 'Something went wrong.', 'error')
                    this.setState({ processing: false });
                })
            })
        }
    }

    renderSubmitBtn = () => {
        if(this.state.processing){
            return <input type="button" className="btn btn-primary" value="Sending..." disabled={true}/> ;
        }else{
            
            return <button type="submit" className="btn btn-primary">Submit</button> ;
        }
    }

    render() {

        if (this.state.EmailStatus) {
            return <Redirect to="/otp-check" />
        }

        return (
            <section className="login-page-register">
                <div className="login-register-box">
                    <div className="text-center login-box-header common-unit">
                        <div className="m-b-30">
                            <Link to="/home"><img src="assets/images/relotech-logo.png" alt="site-logo" /></Link>
                        </div>
                        <h3>Forgot your password?</h3>
                    </div>
                    <br/>
                    <p className="text-center m-b-30 text-muted">Enter your email id.<br/>We'll email instructions on how to reset your password.</p>
                    <form action="#" method="POST" onSubmit={this.handleForgotPassword}>
                        <div className="login-box-body m-t-30">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Email Address</label>
                                        <input type="email" className="form-control" placeholder="Email Address" name="email" onChange={this.changeValue} required />
                                        <p className="text-danger help-block">{this.state.emailErr}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group m-t-40 clearfix text-sm-center">
                                {this.renderSubmitBtn()}&nbsp;
                                <Link to="/login" className="btn btn-link pull-right m-t-8">Cancel</Link>
                            </div>
                        </div>
                        <hr className="m-t-40 m-b-40" />
                        <div className="login-box-footer text-center">
                            <span className="text-grey-400"> Don't have an account?</span>
                            <Link to="/registration-mmc" className="link-btn">Sign Up Now</Link>
                        </div>
                    </form>
                </div>

            </section>
        );
    }
}

export default ForgotPassword;