import React, { Component } from 'react'
import {Link,Redirect} from 'react-router-dom';
import { FormGroupMaterial } from '../externaljs/FormGroupMaterial';
import PostDataWithParam from '../services/PostDataWithParam';

class OtpCheck extends Component {
    state = {
       fields:{
            profile_id:2,
            otp: '',
            new_password:'',
            confirm_password:''
       },
       error: {
        password_err:'',
        confirm_password_err:'',
        otpErr: ''
       },
       otpStatus: false,
       loginErr: '',
       regErr:'',
       processing: false
    }

    componentDidMount() {
        FormGroupMaterial();
    }
    

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }

    resetError = (cb) => {
        let error = {
            password_err:'',
            confirm_password_err:'',
            loginErr: '',
            otpErr: ''
        }
        this.setState({ error: error }, () =>{
            cb();
        });
    }

    handleLogin = () => {
        
        this.resetError(() => {
            let error = this.state.error;
        if (!this.state.fields.otp) {
            this.setState({ otpErr: 'OTP is required.' });
        }
        else if(!this.state.fields.new_password){
            this.setState({ otpErr: '' });
            this.setState({ password_err: 'Password is required.' });
        }
        else if(!this.state.fields.confirm_password){
            this.setState({ password_err: '' });
            this.setState({ confirm_password_err: 'Confirm Password is required.' });
        }
        else if(this.state.fields.new_password != this.state.fields.confirm_password){
            this.setState({ password_err: '' });
            this.setState({ confirm_password_err: 'Password & Confirm Password is not matched.' });
        }
        else {
            this.setState({ otpErr: '' });
            this.setState({ password_err: '' });
            this.setState({ confirm_password_err: '' });
            this.setState({processing: true},()=>{
            PostDataWithParam('VerifyOtp', this.state.fields)
                .then((response) => {
                    let result = response;
                    if (result.success === 1) {
                        window.swal('Success!', response.msg, 'success')
                        this.setState({ otpStatus: true });   
                    } else {
                        window.swal('Error!', response.msg, 'error')
                    }
                    this.setState({processing: false});
                })
                .catch(err => {
                    window.swal('Error!', 'Something went wrong.', 'error')
                }) 
            })
        }
    });
    }

    renderSubmitBtn = () => {
        if(this.state.processing){
            return <input type="button" className="btn btn-primary" value="Confirming..." disabled={true}/>
        }else{
            return <button type="button" className="btn btn-primary" onClick={this.handleLogin}>Confirm</button>
        }
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-danger"><i className="icon-cross position-left"></i>
                    {this.state.loginErr}
                </div>
            )
        }
    }
    render() {
        if (this.state.otpStatus) {
           return <Redirect  to={{
                pathname: "/login",
                state: { regErr: this.state.regErr}
                }}/>
        }
        return (
            <section className="login-page-register">
                <div className="login-register-box">
                    <div className="text-center common-unit login-box-header">
                        <div className="m-b-30">
                        <Link to="/home"><img src="assets/images/relotech-logo.png" alt="site-logo" /></Link>
                        </div>
                        <h3>Forgot your password?</h3>
                    </div>
                    <br/>
                    <p className="text-center m-b-30 text-muted">Please enter the OTP sent to your email address</p>
                        <div className="login-box-body m-t-30">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group form-group-material">
                                        <label className="control-label">OTP <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="Enter OTP" name="otp" onChange={this.changeValue}/>
                                        <p className="text-danger help-block">{this.state.otpErr}</p>
                                    </div>
                                    <div className="form-group form-group-material">
                                        <label className="control-label">New Password <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control"  placeholder="Enter New Password" name="new_password" onChange={this.changeValue} />
                                        <p className="text-danger help-block">{this.state.password_err}</p>
                                    </div>
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Confirm Passowrd <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="Enter Confirm Password" name="confirm_password" onChange={this.changeValue}/>
                                        <p className="text-danger help-block">{this.state.confirm_password_err}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group m-t-40 clearfix text-sm-center">
                                {this.renderSubmitBtn()}&nbsp;
                                <Link to="/login" className="btn btn-link m-t-8 pull-right">Cancel</Link>
                            </div>
                        </div>
                </div>
            </section>          
       );
    }
}

export default OtpCheck;