import React, { Component } from 'react';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';

class PrivacyPolicy extends Component {
    state = {}
    render() {
        return (
            <div>
                <HeaderMain />
                <section className="inner-banner section-gap" style={{ background: 'url(assets/images/privacy-banner.jpg) no-repeat', minHeight: '286px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center text-white">
                                <div className="banner-title">
                                    <h1 className="m-0 common-banner-title">GetMilitaryApproved Disclaimer Policy</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="page-body-wrap p-t-40 p-b-40">
                    <div className="container">
                        <div className="row">
                            <div className="page-section-1">
                                <div className="col-md-12">
                                    <div className="page-content">
                                        <h3>About Using This Website</h3>
                                        <p className="m-0 text-sm-center text-xs-center">
                                            By using our website or App, you are accepting all the terms of this disclaimer notice. If you do not agree with anything in this notice you should not use or access this website.
                                        </p>
                                        <hr />
                                        <h3>Warranties and Liability</h3>
                                        <p className="m-0 text-sm-center text-xs-center">While we have put all necessary work to make sure the content of this website is accurate, the website is provided on an “as is” basis and makes no representations or warranties to the completeness or accuracy of the information on it. While the content of this site is provided in good faith, we do not warrant that the information on our website or App will be up to date, valid, accurate and not misleading, or that this site will forever or always be available.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">
                                            We do not warrant that our servers that make this website and App available will be virus, error or bug free and you accept that it is your duty to make necessary effort to provide protection against such threats. It is advisable to scan any files before downloading.
                                        </p><br />
                                        <p className="m-0 text-sm-center text-xs-center">
                                            Nothing on this website should be taken to constitute professional advice or a formal recommendation and hereby excludes all representations and warranties whatsoever (whether implied by law or otherwise) relating to the content and use of this site.
                                        </p><br />
                                        <p className="m-0 text-sm-center text-xs-center">
                                            In no event will GetMilitaryApproved be liable for any incidental, consequential, indirect, punitive or special damages of any kind, or any other damages of any kind, including, without limitation, those arising from loss of profit, loss of contracts, loss of reputation, goodwill, data, information, income, anticipated savings or business relationships, whether or not GetMilitaryApproved has been advised of the possibility of such damage, arising out of or in connection with the use of our App, website or any linked websites.
                                        </p>
                                        <hr />
                                        <h3>Use of this Website</h3>
                                        <p className="m-0 text-sm-center text-xs-center">By using this website you agree to the exclusions and limitations of liability stated above and accept them as reasonable. Do not use this website if you do not agree that they are reasonable.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">If by chance any of the content of this disclaimer notice is found to be illegal, invalid or unenforceable under the applicable law, that will have no bearing on the enforceability of the rest of the disclaimer notice and the illegal, invalid or unenforceable part shall be amended to the minimum extent necessary to make it legal, valid and enforceable.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">All material on this website, including text and images, is protected by copyright law and such copyright is owned by GetMilitaryApproved unless credited otherwise. It may not be reproduced, copied, republished, downloaded, posted, distributed, broadcast or transmitted in any way without the copyright owner’s consent, except for your own personal, non-commercial use.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">Prior written consent of the copyright owner must be obtained for any other use of material.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">No part of this App or website may be distributed or copied for any commercial purpose or financial gain</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">All intellectual property rights in relation to our App and website are reserved and owned by GetMilitaryApproved.</p>
                                        <hr />
                                        <h3>Links to Other websites and Products</h3>
                                        <p className="m-0 text-sm-center text-xs-center">Links to other websites are provided for the convenience of users only, we accepts no liability or responsibility for content on such websites. We are unable to provide any warranty regarding the accuracy or completeness or legitimacy of the content of such sites, or the reliability, quality or effectiveness of any products provided through external websites. A link to an external site does not imply an endorsement of the views, information or products provided or held by such websites.</p>
                                        <hr />
                                        <h3>Law and Jurisdiction</h3>
                                        <p className="m-0 text-sm-center text-xs-center">This disclaimer notice shall be interpreted and governed by the Georgian Law, and any disputes in relation to it are subject to the jurisdiction of the courts in Georgia.</p>
                                        <hr />
                                        <h3>Variations</h3>
                                        <p className="m-0 text-sm-center text-xs-center">We reserve the right to revise and modify this disclaimer policy at any time, and any revised version will be deemed valid as when first published.</p>
                                        <div className="well m-t-15">
                                            <h3 className="m-t-0 text-dark-blue">Refund policy</h3>
                                            <p className="m-0 text-sm-center text-xs-center">Thank you so much for shopping here with GetMilitaryApproved website (or App).</p>
                                            <p className="m-0 text-sm-center text-xs-center">We don’t issue refunds for service performed on the GetMilitaryApproved platforms. However, in the event where client is not satisfied with a service offered, Subscription to the specific service will not re renew.</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            <FooterMain />
        </div>
         );
    }
}

export default PrivacyPolicy;