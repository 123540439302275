import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import GetData from '../services/GetData';

class Content extends Component {
    state = {
        list:[
            {value:0},{value:0},{value:0},{value:0}
        ]
    }

    componentDidMount() { 
        window.$(window).on("load", function () {
            /* Page Scroll to id fn call */
            window.$("#provider-grid a,a[href='#top'],a[rel='m_PageScroll2id']").mPageScroll2id({
                highlightSelector: "#provider-grid a"
            });

            /* demo functions */
            window.$("a[rel='next']").click(function (e) {
                e.preventDefault();
                var to = window.$(this).parent().parent("section").next().attr("id");
                window.$.mPageScroll2id("scrollTo", to);
            });
        });

        window.$('#specialOfferModal').modal('show');
        this.getSubscriptionList()
    }

    getSubscriptionList = () => {
        GetData('GetSubscriptionListForHomePage')
        .then((response) => {
            if (response.success === 1) {
                this.setState({ list: response.data });  
            }
        })
        .catch(err => {
            this.setState({ list: [] });
        })
    }

    render() {
        return (
            <div>
                <div className="banner">
                    <div className="banner-ovelay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="banner-content text-center text-white">
                                        <h1>grow your moving company <br />With Military Moves</h1>
                                        <p>We specialize in getting movers, like YOU, approved with the government  & <br />
                                        <b>GROW</b> your business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="provider-grid-wrap bg-light-blue">
                    <div className="container">
                        <div className="row">
                            <div id="provider-grid">
                                <div className="col-md-4 col-sm-4">
                                    <a href="#section-1">
                                        <div className="provider-grid-container bg-red text-center">
                                            <i><img src="front-assets/images/icons/white/warehouse.png" width="60" /></i>
                                            <h4 className="m-0 p-t-15 p-b-15">Become a Short Term Storage <br />Provider (SIT)</h4>
                                            <p>Storage-In-Transit approved Warehouse</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <a href="#section-2">
                                        <div className="provider-grid-container bg-white text-center">
                                            <i><img src="front-assets/images/icons/black/warehouse.svg" width="50" /></i>
                                            <h4 className="m-0 p-t-15 p-b-15">Become a Long Term Storage<br /> Provider (NTS)</h4>
                                            <p>Non-Temporary Storage approved Warehouse</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <a href="#section-3">
                                        <div className="provider-grid-container bg-dark-blue text-center">
                                            <i><img src="front-assets/images/icons/white/truck.svg" width="50" /></i>
                                            <h4 className="m-0 p-t-15 p-b-15">Become an Approved Transportation<br /> Service Provider (TSP)</h4>
                                            <p>Be able to book for service moves directly from the Government </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="provider-details-wrap bg-light-blue" id="section-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="m-0 text-center common-title f-s-35 text-semi-bold">Become a Short Term Storage Provider (SIT)</h2>
                                <p className="m-0 text-center common-paragraph p-b-40">30, 60, 90, 180 day storage services, including delivery to residence</p>
                            </div>
                            <div className="col-md-6">
                                <figure className="provider-details-picture">
                                    <img src="front-assets/images/provider-pic1.jpg" alt="" />
                                </figure>
                            </div>
                            <div className="col-md-6">
                                <div className="provider-details-content p-t-30 p-l-30">
                                    <div className="media p-b-30">
                                        <div className="media-left">
                                            <i className="bg-white"><img src="front-assets/images/icons/dark-blue/stopwatch.svg" width="30" alt="" /></i>
                                        </div>
                                        <div className="media-body p-l-30">
                                            <h5 className="m-0 p-b-10">Quickest &amp; Easiest approval process</h5>
                                            <p className="m-0">Put your unused warehouse space to work &amp; increase the diversification of your services.</p>
                                        </div>
                                    </div>
                                    <div className="media p-b-30">
                                        <div className="media-left">
                                            <i className="bg-white"><img src="front-assets/images/icons/dark-blue/increased-income.svg" width="30" alt="" /></i>
                                        </div>
                                        <div className="media-body p-l-30">
                                            <h5 className="m-0 p-b-10">Grow your Revenue $$</h5>
                                            <p className="m-0">Average Storage-In-Transit warehouse annual revenue is over $ 300,000.</p>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-left">
                                            <i className="bg-white"><img src="front-assets/images/icons/dark-blue/review.svg" width="30" alt="" /></i>
                                        </div>
                                        <div className="media-body p-l-30">
                                            <h5 className="m-0 p-b-10">Approval = More Jobs</h5>
                                            <p className="m-0">Once approved, you will begin receiving orders for origin and destination services & so much more from other approved service providers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center p-t-40">
                                <Link to={{
                                            pathname: '/registration-mmc',
                                            state: { subscription_id: 1,amount: 0}
                                        }} className="page-btn bg-red">Get Approved Now</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="provider-details-wrap" id="section-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="m-0 text-center common-title f-s-35 text-semi-bold">Become a Long Term Storage Provider (NTS)</h2>
                                <p className="m-0 text-center common-paragraph p-b-40">Non-Temporary Storage Providers (NTS) Pack, Pickup & Store household goods for 1 to 10 years.</p>
                            </div>
                            <div className="col-md-6">
                                <div className="provider-details-content p-t-30">
                                    <div className="media p-b-30">
                                        <div className="media-left">
                                            <i><img src="front-assets/images/icons/dark-blue/revenue.svg" width="30" alt="" /></i>
                                        </div>
                                        <div className="media-body p-l-30">
                                            <h5 className="m-0 p-b-10">More Services, More Revenue $$ </h5>
                                            <p className="m-0">You will pack, pickup & store shipments
                                based on your annually filed pricing. </p>
                                        </div>
                                    </div>
                                    <div className="media p-b-30">
                                        <div className="media-left">
                                            <i><img src="front-assets/images/icons/dark-blue/statistics.svg" width="30" alt="" /></i>
                                        </div>
                                        <div className="media-body p-l-30">
                                            <h5 className="m-0 p-b-10">Long Term Passive Revenue $$</h5>
                                            <p className="m-0">Once a shipment is in storage, you invoice the government for services completed. Then
                                quarterly thereafter for additional storage charges. Great Recurring Revenue!</p>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-left">
                                            <i><img src="front-assets/images/icons/dark-blue/money-bag.svg" width="30" alt="" /></i>
                                        </div>
                                        <div className="media-body p-l-30">
                                            <h5 className="m-0 p-b-10">Large Revenue Opportunity</h5>
                                            <p className="m-0">Average NTS service providers’ revenue exceeds $1,000,000 annually.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <figure className="provider-details-picture">
                                    <img src="front-assets/images/provider-pic2.jpg" alt="" />
                                </figure>
                            </div>
                            <div className="col-md-12 text-center p-t-40">
                               <Link to={{
                                            pathname: '/registration-mmc',
                                            state: { subscription_id: 1,amount: 0}
                                        }} className="page-btn bg-red">Get Approved Now</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="provider-details-wrap bg-light-blue" id="section-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="m-0 text-center text-semi-bold">Become a Transportation Service Provider (TSP)</h1>
                                <p className="m-0 text-center common-paragraph p-b-40">TSP&rsquo;s provide end to end relocation services to include: packing, hauling, storage & delivery of household goods shipments.</p>
                            </div>
                            <div className="col-md-6">
                                <figure className="provider-details-picture">
                                    <img src="front-assets/images/provider-pic3.jpg" alt="" />
                                </figure>
                            </div>
                            <div className="col-md-6">
                                <div className="provider-details-content p-t-30 p-l-30">
                                    <div className="media p-b-30">
                                        <div className="media-left">
                                            <i className="bg-white"><img src="front-assets/images/icons/dark-blue/star.svg" width="30" alt="" /></i>
                                        </div>
                                        <div className="media-body p-l-30">
                                            <h5 className="m-0 p-b-10">400,000 Reasons To get Approved</h5>
                                            <p className="m-0">Department of Defense has approximately only 1000 TSP’s, to service such a large quantity of Military relocations!<br/>
(400k in 2017).</p>
                                        </div>
                                    </div>
                                    <div className="media p-b-30">
                                        <div className="media-left">
                                            <i className="bg-white"><img src="front-assets/images/icons/dark-blue/statistics.svg" width="30" alt="" /></i>
                                        </div>
                                        <div className="media-body p-l-30">
                                            <h5 className="m-0 p-b-10">High Revenue Growth $$</h5>
                                            <p className="m-0">According to Government Accounting Office, DOD spends $16,000 per relocation. Average
                                TSP’s charges to the Government $3,200,000. (2016)</p>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-left">
                                            <i className="bg-white"><img src="front-assets/images/icons/dark-blue/settings.svg" width="30" alt="" /></i>
                                        </div>
                                        <div className="media-body p-l-30">
                                            <h5 className="m-0 p-b-10">DOD Opens the Door to MORE Opportunity</h5>
                                            <p className="m-0">Working for the DOD allows your team to gather the
                                                insider knowledge to obtain contracts from the GSA,
                                                FBI, DHS & so many more departments of
                                the government!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-center p-t-40">
                                <Link to={{
                                            pathname: '/registration-mmc',
                                            state: { subscription_id: 1,amount: 0}
                                        }} className="page-btn bg-red">Get Approved Now</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="process-list-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h2 className="m-0 text-center common-title f-s-35 text-semi-bold">Our Simple Process Leads Your Success</h2>
                                <p className="common-paragraph p-b-40">GetMilitaryApprove's unique technology and in-depth knowledge of the approval process ensures our client’s experience is STRESS-FREE & EASY. We are here to guide
                                    your company to success and build a LONG term profitable relationship. We will show your team how to become a successful service provider for
                    the world's best military.</p>
                            </div>
                            <div className="col-md-12">
                                <div className="process-list-container">
                                    <ul className="process-list p-0">
                                        <li>
                                            <i><img src="front-assets/images/icons/dark-blue/favorites.svg" width="50" /></i>
                                            <h5>Subscribe &amp; Complete your Profile</h5>
                                            <p>Our advanced profile questionnaire ensures an expedited approval process by the government. Our easy to use communication tools keep our teams connected from day one.</p>
                                            <Link to="/registration-mmc">Become a Member now</Link>
                                        </li>
                                        <li>
                                            <i><img src="front-assets/images/icons/dark-blue/result.svg" width="50" /></i>
                                            <h5>Research &amp; Planning</h5>
                                            <p>Your team uploads and provides documents, pictures, and other needed items for your approval package submission.</p>
                                            <p>(Don’t worry, <img src="front-assets/images/icons/smiling.svg" width="20"/> <br/> we will guide you!)</p>
                                        </li>
                                        <li>
                                            <i><img src="front-assets/images/icons/dark-blue/file.svg" width="50" /></i>
                                            <h5>Execution</h5>
                                            <p>Our team reviews your profile, documents, and all other important information submitted. We provide a custom plan of action to obtain approval ASAP for one or more programs. We direct your team on how to address any deficiencies to ensure expedited approval.</p>
                                        </li>
                                        <li>
                                            <i><img src="front-assets/images/icons/dark-blue/review.svg" width="50" /></i>
                                            <h5>Final Review, Submission and Ongoing Assistance</h5>
                                            
                                            <p><span className="icon-circle-small"></span> We review and help complete your application package.<br/>
                                            <span className="icon-circle-small"></span> Submit it for approval<br/>
                                            <span className="icon-circle-small"></span> Assist with any questions from the Government to ensure your APPROVAL.</p>
                                           
                                        </li>
                                        <li>
                                            <i className="p-t-35"><img src="front-assets/images/icons/dark-blue/tick.svg" width="40" /></i>
                                            <h5>Approval, Ongoing Training and Marketing</h5>
                                            <p>We will continue to assist your team in ‘How to do business’ with the Government.<br/>
                                             <strong>Includes,</strong> ongoing training for your team.<br/><strong>Plus,</strong> marketing to the Government and other approved service providers.</p>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-12 text-center p-t-10">
                                <a href="#" className="page-btn bg-red">Become a Member</a>
                                <p className="p-t-30 process-footer-text"><span>GROW</span> your moving business with the <b>US Military</b> and  <span>expand</span> your network.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="subscription-wrap text-center" id="section-pricing">
                    <div className="subscription-overlay p-t-40 p-b-40">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-b-40 p-t-20">
                                    <h2 className="m-0 text-center common-title p-b-20 p-t-20 f-s-35 text-semi-bold">Subscription Plans</h2>
                                </div>

                                <div className="col-md-3 col-sm-6">
                                    <div className="pricing text-center">
                                        <div className="pricing-header bg-gradient-blue">
                                            <h6 className="pricing-title m-0">Free Membership</h6>
                                        </div>
                                        <div className="pricing-body">
                                            <div className="pricing-price text-blue p-t-20">
                                                {(this.state.list[0].value==0)?'FREE':'$ '+this.state.list[0].value+' USD'}
                                            </div>
                                            <ul className="pricing-list">
                                                <li>Search our database of : <br/>
                                                    2,000+ Pro ReloHelpers 
                                                    <br/>
                                                    2,500+ Moving Companies.
                                                </li>
                                                <li>$$$ Money Saving Partner Discounts on Truck Rental, Packing Material, Hotels, Fuel, Moving Equipment, Bookkeeping, and many other products and services.</li>
                                                <li>Networking with fellow Relo Members ( Movers, Helpers &amp; many more)</li>                                                 
                                                <li>Preview our New Tech for Movers,
                                                    Drivers, Customers, and Helpers</li>                                                
                                                <li>Newsletter</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer p-t-20 p-b-20">
                                            <Link to={{
                                                        pathname: '/registration-mmc',
                                                        state: { subscription_id: 1,amount: this.state.list[0].value}
                                                    }} 
                                                    className="pricing-footer-bg-blue">Select</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-sm-6">
                                    <div className="pricing text-center">
                                        <div className="pricing-header bg-gradient-green">
                                            <p>popular</p>
                                            <h5 className="pricing-title m-0">Monthly</h5>
                                            <div className="offer">
                                                <span>50%</span><br/>
                                                <b>OFF</b>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <div className="pricing-price text-green p-t-20">
                                                <span className="pricing-currency">$</span> {this.state.list[1].value} <span>USD</span>
                                                <p>All benefits of free membership</p>
                                                <i><img src="front-assets/images/icons/green/add.svg" width="20" /></i>
                                            </div>
                                            <ul className="pricing-list">
                                                <li>We assist you in getting APPROVED to work for Department of Defense</li>
                                                <li>On-Going training in all areas of "How to work with Department of Defense", reduce claims plus so many other topics!</li>
                                                <li>Customized solutions to reduce your claims by 75% and increase your customer satisfaction scores and profits by 50% **</li>
                                                <li>Proposal writing services for Request for Proposals (RFPs).** </li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer p-t-20 p-b-20">
                                                    <Link to={{
                                                        pathname: '/registration-mmc',
                                                        state: { subscription_id: 2,amount: this.state.list[1].value}
                                                    }} className="pricing-footer-bg-green">Select</Link>
                                            <p className="p-t-10 m-0">**Add’l charges may apply</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-sm-6">
                                    <div className="pricing text-center">
                                        <div className="pricing-header bg-gradient-pink">
                                            <h6 className="pricing-title m-0">yearly</h6>
                                            <div className="offer">
                                                <span>30%</span><br/>
                                                <b>OFF</b>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <div className="pricing-price text-pink p-t-20">
                                                <span className="pricing-currency">$</span> {this.state.list[2].value} <span>usd </span>
                                                <p>All benefits of free &amp; monthly membership</p>
                                                <i><img src="front-assets/images/icons/pink/add.svg" width="20" /></i>
                                            </div>
                                            <ul className="pricing-list">
                                <li>You save $$ by prepaying. </li>
                            </ul>
                                        </div>
                                        <div className="pricing-footer p-t-20 p-b-20">
                                                    <Link to={{
                                                        pathname: '/registration-mmc',
                                                        state: { subscription_id: 3,amount: this.state.list[2].value}
                                                    }} className="pricing-footer-bg-pink">Select</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-sm-6">
                                    <div className="pricing text-center">
                                        <div className="pricing-header bg-gradient-orange">
                                            <h6 className="pricing-title m-0">LIFETIME</h6>
                                        </div>
                                        <div className="pricing-body">
                                            <div className="pricing-price text-orange p-t-20">
                                                <span className="pricing-currency">$</span> {this.state.list[3].value} <span>usd </span>
                                                <p>All benefits of free &amp; monthly membership</p>
                                                <i><img src="front-assets/images/icons/orange/add.svg" width="20"/></i>
                                            </div>
                                            <ul className="pricing-list">
                                                <li>We will work with you over the
                                                    lifetime of your business to ensure
                                                    the most profitable and 
                                                    sustainable Business model!
                                                </li>
                                                <li>Never have to worry about getting
                                                    charged again!<br/>
                                                    (Except for Customized Services)
                                                </li>                                                
                                            </ul>
                                        </div>
                                        <div className="pricing-footer p-t-20 p-b-20">
                                                    <Link to={{
                                                        pathname: '/registration-mmc',
                                                        state: { subscription_id: 4,amount: this.state.list[3].value}
                                                    }} className="pricing-footer-bg-orange">Select</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="specialOfferModal" className="modal fade offer-zone" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content text-center">
                            <div className="special-offer">
                                <img src="front-assets/images/specialoffer.png"/>
                            </div>
                            <div className="modal-body">
                                <p className="p-b-20 f-s-20">
                                    Join today for <b className="text-success">FREE</b> access to our database of over 2,000+ ReloHelpers
                                    to assist your drivers and operations team and  getting the JOB done right nationwide..
                                </p>
                                <a href="" className="btn btn-danger btn-lg bg-red" data-dismiss="modal">Find Out How (It's Free)</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;