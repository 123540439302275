import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';
class Wwa extends Component {
    state = {}

    componentDidMount() { 
        window.$(window).on("load", function () {
            /* Page Scroll to id fn call */
            window.$("#provider-grid a,a[href='#top'],a[rel='m_PageScroll2id']").mPageScroll2id({
                highlightSelector: "#provider-grid a"
            });

            /* demo functions */
            window.$("a[rel='next']").click(function (e) {
                e.preventDefault();
                var to = window.$(this).parent().parent("section").next().attr("id");
                window.$.mPageScroll2id("scrollTo", to);
            });
        });

    }

    render() {
        return (
            <div>
                 <HeaderMain />
            <div className="banner about-banner">
    <div className="banner-ovelay">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner-content text-center text-white">
                        <h1 className="m-0 common-banner-title">Who we are</h1>
                        <p className="m-0 p-b-10 common-banner-title-pera">"Great things in business are never done by one person; they're done by a team of people."</p>
                        <p className="m-0">- Steve Jobs -</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section className="page-body-wrap p-t-40 p-b-40">
    <div className="container">
        <h1 className="m-0 text-uppercase text-sm-center f-s-35 text-xs-center m-b-15">Who we are</h1>
        <div className="row">
            <div className="page-section-1">
                <div className="col-md-7">
                    <div className="page-content">
                        <p class="m-0 text-sm-center text-xs-center">
                            GetMilitaryApproved by Courageous Endeavors, Inc, was founded by U.S.
                            Marine Corps veteran, Tony Boncimino, who had seen the horrors of relocation
                            from both the military and the moving industry perspective. As a US Marine,
                            the ability and knowledge to overcome and adapt to the changing household goods
                            world has been instilled in the team at GetMilitaryApproved. <br/>Our ONLY mission at GetMilitaryApproved,
                            is to assist our clients in reaching new heights and increase the overall value
                            and customer service for the relocation customer. This mission will ensure our 
                            clients provide the best experience for all customers, including our military 
                            families who deserve the best relocation, we can give them.
                        </p>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="page-picture">
                        <img src="front-assets/images/trak.jpg" alt=""/>
                    </div>
                </div>
            </div>
            <div className="page-section-2 p-t-40 p-b-20 inline-block">                
                <div className="col-md-4">
                    <div className="founder-picture p-b-20">
                        <div className="picture-border m-t-15">
                            <img src="front-assets/images/founder-family.jpg" alt="" className="img-responsive"/>
                        </div>
                        <p className="m-0 p-b-5 p-t-10">Tony Boncimino &amp; family</p>
                        <span>Founder</span>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="founder-content">  
                        <p className="m-b-30 text-sm-center text-xs-center">
                            <span className="text-semi-bold">&quot;Family is not an important thing. It&rsquo;s everything&quot;</span> is the guiding principle for founder, Tony Boncimino, and is woven into the fabric of our family centered organization. This dedication to family drives GetMilitaryApproved to bring the best relocation experience to our military families by helping your company join the elite family of military approved service providers.
                        </p>                     
                        <p className="m-0 text-sm-center text-xs-center">
                            As the founder of Precision Movers, Inc., a military approved Storage-in-Transit 
                            and Transportation Service Provider (TSP), Tony has seen the many changes to the
                            Department of Defense/ USTRANSCOM household goods program.<br/> In early 2018, he left
                            Precision Movers, Inc. in capable hands, and has devoted himself to bring structural
                            change to the relocation industry thru technology by building the GetMilitaryApproved Platform.
                            This one of a kind system, will revolutionize the relocation industry by connecting
                            the customer, military, mover, driver, and helper in ways never seen before.<br/>We look forward to you joining the GetMilitaryApproved family. 
                        </p>
                    </div>
                </div>
            </div>
            <div className="page-section-3 p-b-40 inline-block">
                <div className="col-md-12">
                    <h3 className="m-0 p-b-15 f-s-25 text-medium">In September 2018, Rear Admiral Clarke, who oversees USTRANSCOM, gave GetMilitaryApproved staff a
                        critical mission- Find radical and groundbreaking ways to do the following:</h3>
                    <ul className="listing">
                        <li>Expand Industry capacity (get more movers to participate in the program).</li>
                        <li>Create solutions to increase customer satisfaction and lower damage claims for military families.</li>
                        <li>Cost Saving solutions for USTRANSCOM to add efficiency and expand mission critical capabilities.</li>
                    </ul>
                    <p class="m-0 p-t-20">
                        We have designed GetMilitaryApproved from the ground up to ensure the highest approval
                        rate of service providers, (movers like you <img src="front-assets/images/icons/smiling.svg" width="20"/>), to be added to the 
                        USTRANSCOM programs. In addition by harnessing technology, we are 
                        connecting 1000’s of movers, helpers, and military personnel in a secure,
                        custom built environment, which will allow us to expand our products in a 
                        variety of ways over the next 24 months. 
                    </p>
                    <p class="text-center m-t-25 text-semi-bold">We look forward to your partnership
                        and hope you remain a member for many years to come. </p>
                </div>
                <div className="col-md-12 text-center p-t-30">
                    <a href="#" class="page-btn bg-red text-white btn">Become a Member Today to Change the Relocation World.<br/>(It's FREE)</a>
                </div>
            </div>
        </div>
    </div>
</section>
<FooterMain />
</div>
        );
    }
}

export default Wwa;