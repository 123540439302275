import React, { Component } from 'react';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';

class SubscriptionAgreement extends Component {
    state = {}
    render() {
        return (
            <div>
                <HeaderMain />
                <section className="inner-banner section-gap" style={{background: 'url(assets/images/privacy-banner.jpg) no-repeat', minHeight: '286px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center text-white">
                                <div className="banner-title">
                                    <h1 className="m-0 common-banner-title">GetMilitaryApproved Subscription Agreement</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-body-wrap p-t-40 p-b-40">
                    <div className="container">
                        <div className="row">
                            <div className="page-section-1">
                                <div className="col-md-12">
                                    <div className="page-content">
                                        <p className="m-0 text-sm-center text-xs-center">
                                            This subscription agreement (the “Agreement”), the relevant terms of the Documentation, and any executed Orders and/or SOWs between the parties, are incorporated herein and shall govern the provision of the Services. Customer and its Affiliates may place orders under this Agreement by submitting separate Order(s) and SOW(s). This Agreement shall commence on the Effective Date of Customer’s first executed Order or SOW (“Effective Date”) and will continue until otherwise terminated in accordance with Section 12 below.
                                        </p><br />
                                        <p className="m-0 text-sm-center text-xs-center">1. DEFINITIONS.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">"Affiliate” means any entity that directly or indirectly controls, is controlled by, or is under common control with the subject entity. "Control," for purposes hereof, means direct or indirect ownership or control of more than 50% of the voting interests of the subject entity.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Authorized Contractors” means independent contractors, licensors or subcontractors that assist GetMilitaryApproved in the delivery of the Services.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Customer Applications” means all software programs that Customer uses on GetMilitaryApproved platform comprising part of the Subscription Services. Subscription Services do not fall within the meaning of Customer Applications.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">"Customer Data" means all data, records, files, images, graphics, audio, video, photographs, reports, forms and other content and material, in any format, that are submitted, stored, posted, displayed, transmitted or otherwise used with the Subscription Services.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Data Center Region” refers to the geographic region in which the Customer Data is housed.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Deliverable” means any work product, deliverables, programs, interfaces, modifications, configurations, reports, or documentation developed or delivered in the performance of Professional Services.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Documentation” means GetMilitaryApproved product guides and other end user documentation for the Subscription Services available online and through the help feature of the Subscription Services, as may be updated by GetMilitaryApproved from time to reflect the then-current Subscription Services.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Order” or “Order Form” means an ordering document or online order specifying the Services to be provided hereunder that is entered into between GetMilitaryApproved and Customer from time to time, including any addenda and supplements thereto. Customer Affiliates may purchase Services subject to this Agreement by executing Orders hereunder.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Professional Services” means fee-based services, implementation, training or consulting services that GetMilitaryApproved performs as described in an Order or SOW, but excluding Support Services to get approval.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">"Services" means the Subscription Services and Professional Services that Customer may purchase under an Order or SOW.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Statement of Work” or “SOW” means a statement of work entered into and executed by the parties describing Professional Services to be provided by GetMilitaryApproved to Customer.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Subscription Services” means the cloud platform made available by GetMilitaryApproved to Customer, the software made available by GetMilitaryApproved to Customer online via the applicable customer logins and/or associated Support Services, as ordered by Customer under an Order, as applicable.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Support Services” means the level of support services purchased by Customer pursuant to an Order.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Subscription Term” means the term of Subscription Services purchased by Customer which shall commence on the start date specified in the applicable Order and continue for the subscription term specified therein and any renewals thereto.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Trial Services” means any GetMilitaryApproved product, service or functionality that may be made available by GetMilitaryApproved to Customer to try at Customer’s option, at no additional charge, and which is designated as “beta,” “trial,” “non-GA,” “pilot,” “developer preview,” “non-production,” “evaluation,” or by a similar designation.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">"Third Party Marketplace" means any non-GetMilitaryApproved products or services made available as an accommodation through GetMilitaryApproved’s website, which are subject to change during the Subscription Term.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">“Customer” means any registered user on the GetMilitaryApproved cloud platform:</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">Moving company, driver, helper, and clients.</p><br />
                                        <hr />
                                        <p className="m-0 text-sm-center text-xs-center">2. SUBSCRIPTION SERVICES</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">2.1. Provision of Subscription Services. GetMilitaryApproved will make the Subscription Services available to Customer pursuant to this Agreement, the Documentation and the relevant Order Form during the Subscription Term, solely for Customer’s internal business purposes. GetMilitaryApproved’ s Affiliates and its Authorized Contractors may perform certain aspects of the Services provided that GetMilitaryApproved remain fully liable for same and responsible for ensuring that any of GetMilitaryApproved obligations under this Agreement performed by its Affiliates and its Authorized Contractors are carried out in accordance with this Agreement. Customer's use of the Subscription Services includes the right to access all functionality available in the Subscription Services during the Subscription Term. So long as GetMilitaryApproved does not materially degrade the functionality, as described in the Documentation, of the Subscription Services during the applicable Subscription Term (i) GetMilitaryApproved may modify the systems and environment used to provide the Subscription Services to reflect changes in technology, industry practices and patterns of system use, and (ii) update the Documentation accordingly. Subsequent updates, upgrades, enhancements to the Subscription Services made generally available to all subscribing customers will be made available to Customer at no additional charge, but the purchase of Subscription Services is not contingent on the delivery of any future functionality or features. New features, functionality or enhancements to the Subscription Services may be marketed separately by GetMilitaryApproved and may require the payment of additional fees. GetMilitaryApproved will determine, in its sole discretion, whether access to such new features, functionality or enhancements will require an additional fee.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">2.2 Trial Services. If Customer registers or accepts an invitation for Trial Services, including through GetMilitaryApproved website, or executes an Order for the same, GetMilitaryApproved will make such Trial Services available to Customer on a trial basis, at the agreed trial charge, until the earlier of (a) the end of the free trial period for which Customer registered to use the applicable Trial Services, or (b) the end date specified in the applicable Order. Trial Services are provided for evaluation purposes and not for production use. Customer shall have sole responsibility and GetMilitaryApproved assumes no liability for any Customer Data that Customer may choose to upload on the Trial Services. Trial Services may contain bugs or errors, and may be subject to additional terms. TRIAL SERVICES ARE NOT CONSIDERED "SERVICES" HEREUNDER AND ARE PROVIDED "AS IS" WITH NO EXPRESS OR IMPLIED WARRANTY. GetMilitaryApproved may, in its sole discretion, discontinue Trial Services at any time.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">2.3. Third Party Marketplace. As part of the Subscription Services, GetMilitaryApproved may provide access to the Third Party Marketplace solely as an accommodation to Customer. Customer may choose to use any, all or none of the offerings on such Third Party Marketplace at its sole discretion. Customer’s use of any offering on the Third Party Marketplace is subject to the applicable provider’s terms and conditions and any such terms and conditions associated with such use are solely between Customer and such third party provider. GetMilitaryApproved does not provide any Support Services for Third Party Marketplace products and services.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">3. SECURITY AND DATA PRIVACY</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">3.1. Security and Internal Controls. In accordance with GetMilitaryApproved Security Annex incorporated herein by reference, GetMilitaryApproved shall (i) maintain a security framework of policies, procedures, and controls that includes administrative, physical, and technical safeguards for protection of the security and integrity of the Subscription Services, and of the Customer Data contained within the Subscription Services, using the capabilities of currently available technologies and in accordance with prevailing industry practices and standards, (ii) access and use the Customer Data solely to perform its obligations in accordance with the terms of this Agreement, and (iii) perform periodic testing by independent third party audit organizations, which include with Service Organization Controls 1 (SOC 1), SOC 2 audits and ISO 27001 certification or surveillance audits performed annually. In no event during the Subscription Term shall GetMilitaryApproved materially diminish the protections provided by the controls set forth in GetMilitaryApproved then-current Security Annex.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">
                                            3.2. Data Privacy. In performing the Subscription Services, GetMilitaryApproved will comply with the GetMilitaryApproved Privacy Policy incorporated herein by reference. The GetMilitaryApproved Privacy Policy is subject to change at GetMilitaryApproved discretion; however, GetMilitaryApproved policy changes will not result in a material reduction in the level of protection provided for Customer Data during the Subscription Term. Where Customer’s use of the Subscription Services includes the processing of Customer Data by GetMilitaryApproved that are subject to the General Data Protection Regulation (EU) 2016/679, such data processing by GetMilitaryApproved as data processor complies with the requirements of the aforementioned regulation and, in addition, with the EU -U.S. and Swiss-U.S. Privacy Shield for which GetMilitaryApproved is certified. GetMilitaryApproved shall process personal data on behalf of and in accordance with Customer’s instructions consistent with this Agreement and as necessary to provide the Subscription Services.
                                        </p><br />
                                        <p className="m-0 text-sm-center text-xs-center">3.3. Data Center Region. Customer may select the Data Center Region from those available for the applicable Subscription Services. GetMilitaryApproved will not move the selected Data Center Region without Customer’s written consent or unless required to comply with the law or requests of a governmental or regulatory body (court orders). Customer consents to GetMilitaryApproved storage of Customer Data in, and transfer of Customer Data into, the Data Center Region Customer selects.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">3.4. Compliance with Law. GetMilitaryApproved will comply with all laws applicable to the provision of the Subscription Services, including applicable security breach notification laws, but not including any laws applicable to the Customer’s industry that is not generally applicable to information technology services providers.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">4. CUSTOMER OBLIGATIONS.</p>
                                        <p className="m-0 text-sm-center text-xs-center">4.1. Responsibilities. Customer shall (i) access and use the Services in accordance with this Agreement, applicable laws and government regulations and GetMilitaryApproved Acceptable Use Policy incorporated herein by reference, (ii) use commercially reasonable efforts to prevent unauthorized access to or use of the Services, and notify GetMilitaryApproved promptly of any such unauthorized access or use, and (iii) take commercially reasonable steps necessary to ensure the security and compliance of the Customer Applications.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">4.2. Customer Data. Customer is responsible for its legal and regulatory compliance in its use of any Subscription Services and shall make GetMilitaryApproved aware of any Customer Data processed, stored or transmitted through the Subscription Services for which regulations other than those set forth in the Security Annex apply. If, in the course of providing Subscription Services, GetMilitaryApproved agrees in writing to process such Customer Data and Customer has subscribed to any applicable Subscription Services, GetMilitaryApproved shall process it only as permitted under this Agreement and in compliance with data protection legislation to which GetMilitaryApproved is subject as a service provider.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">4.3 Restrictions. Customer shall not (a) license, sublicense, sell, resell, rent, lease, transfer, distribute or otherwise similarly exploit the Subscription Services; (b) use or permit others to use any security testing tools in order to probe, scan or attempt to penetrate or ascertain the security of the Subscription Services; (c) copy, create a derivative work of, reverse engineer, reverse assemble, disassemble, or decompile the Subscription Services or any part thereof or otherwise attempt to discover any source code or modify the Subscription Services; or (d) disclose any benchmark or performance tests of the Subscription Services.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">5. PROFESSIONAL SERVICES</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">5.1. Standard Professional Services. A description of GetMilitaryApproved standard Professional Services offering ncludes but not limited to:</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">a. Consulting service to assist relocation companies in getting approved to carry out military jobs. We do not guarantee approval, but over time our success rate as hit almost 99.99%.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">b. Connecting relocation companies with other industry professionals. <a>https://GetMilitaryApproved.com </a>.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">5.2. Other Professional Services. For any non-standard Professional Services, GetMilitaryApproved will provide Customer with Professional Services as set forth in the applicable SOW. Each SOW will include, at a minimum</p><br />
                                        <p className="m-0 text-sm-center text-xs-center"> (i) a description of the Professional Services and any Deliverable to be delivered to Customer; (ii) The scope of Professional Services; (iii) the schedule for the provision of such Professional Services; and (iv) the applicable fees and payment terms for such Professional Services, if not specified elsewhere.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">5.3. Change Orders. Changes to an SOW or Order Form will require, and shall become effective only when, fully documented in a written change order (each a “Change Order”) signed by duly authorized representatives of the parties prior to implementation of the changes. Such changes may include, for example, changes to the scope of work and any corresponding changes to the estimated fees and schedule. Change Orders shall be deemed part of, and subject to, this Agreement..</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">5.4. Designated Contact and Cooperation. Each party will designate in each SOW an individual who will be the primary point of contact between the parties for all matters relating to the Professional Services to be performed thereunder. Customer will cooperate with GetMilitaryApproved, will provide GetMilitaryApproved with accurate and complete information, will provide GetMilitaryApproved with such assistance and access as GetMilitaryApproved may reasonably request, and will fulfill its responsibilities as set forth in this Agreement and the applicable SOW. If applicable, while on Customer premises for Professional Services, GetMilitaryApproved personnel shall comply with reasonable Customer rules and regulations regarding safety, conduct, and security made known to GetMilitaryApproved.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">6. FEES AND PAYMENT</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">6.1. Fees. Customer shall pay all fees specified in each Order and SOW and any applicable additional fees if Customer exceeds the allotted capacity or other applicable limits specified in the Order. Except as otherwise specified herein or in an Order or SOW </p><br/>
                                        <p className="m-0 text-sm-center text-xs-center">(i) fees are payable in United States dollars,<br /> (ii) fees are based on Services purchased, regardless of usage, <br />(iii) payment obligations are non-cancelable and fees paid are non-refundable,
                                        <br />(iv) All Services shall be deemed accepted upon delivery, and <br />(v) the Subscription Services purchased cannot be decreased during the relevant Subscription Term. Customer shall reimburse GetMilitaryApproved for out-of-pocket expenses incurred by GetMilitaryApproved in connection with its performance of Services. GetMilitaryApproved will provide Customer with reasonably detailed invoices for such expenses. All amounts payable under this Agreement will be made without setoff or counterclaim, and without any deduction or withholding.
                                        </p><br />
                                        <p className="m-0 text-sm-center text-xs-center">6.2. Invoicing and Payment. Unless otherwise specified in an Order, fees for Subscription Services specified in an Order will be invoiced annually in advance, fees for overages will be calculated and invoiced monthly in arrears, and, unless otherwise set forth in an SOW, all fees and expenses for standard Professional Services as described in Section 5.1 shall be invoiced upon completion, and all fees and expenses for non-standard Professional Services as described in 5.2 will be invoiced monthly in arrears on a time and materials basis. Except as otherwise stated in the applicable Order or SOW, Customer agrees to pay all invoiced amounts within thirty (30) days of invoice date. If Customer fails to pay any amounts due under this Agreement by the due date, in addition to any other rights or remedies it may have under this Agreement or by matter of law (i) GetMilitaryApproved reserves the right to suspend the Subscription Services upon thirty (30) days’ notice, until such amounts are paid in full, and (ii) GetMilitaryApproved will have the right to charge interest at a rate equal to the lesser of one and one-half percent (1.5%) per month or the maximum rate permitted by applicable law until Customer pays all amounts due; provided that GetMilitaryApproved will not exercise its right to charge interest if the applicable charges are under reasonable and good faith dispute and Customer is cooperating diligently to resolve the issue.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">6.3. Taxes. Fees for Services exclude all sales, value added and other taxes and duties imposed with respect to the sale, delivery, or use of any product or Services covered hereby. Unless Customer provides a valid, signed certificate or letter of exemption for each respective jurisdiction of its tax-exempt status, Customer is responsible for payment of all taxes, levies, duties, assessments, including but not limited to value-added, sales, use or withholding taxes, assessed or collected by any governmental body (collectively, "Taxes") arising from GetMilitaryApproved provision of the Services hereunder, except any taxes assessed on GetMilitaryApproved net income. If GetMilitaryApproved is required to directly pay or collect Taxes related to Customer’s use or receipt of the Services hereunder, Customer agrees to promptly reimburse GetMilitaryApproved for any amounts paid by GetMilitaryApproved.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">7. PROPRIETARY RIGHTS</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">7.1. Subscription Services. Except for the rights expressly granted under this Agreement, GetMilitaryApproved and its licensors retain all right, title and interest in and to the Subscription Services and Documentation, including all related intellectual property rights therein. GetMilitaryApproved reserves all rights in and to the Subscription Services and Documentation not expressly granted to Customer under this Agreement. Customer will not delete or in any manner alter the copyright, trademark, and other proprietary notices of GetMilitaryApproved.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">7.2. Customer Data and Customer Applications. As between Customer and GetMilitaryApproved, Customer is and will remain the sole and exclusive owner of all right, title and interest to all Customer Data and Customer Applications, including any intellectual property rights therein. Customer hereby grants GetMilitaryApproved, its Affiliates and applicable Authorized Contractors all necessary rights to host, use, process, store, display and transmit Customer Data and Customer Applications solely as necessary for GetMilitaryApproved to provide the Services in accordance with this Agreement. Subject to the limited licenses granted herein, GetMilitaryApproved acquires no right, title or interest from Customer or Customer licensors hereunder in or to Customer Data and Customer Applications, including any intellectual property rights therein.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">7.3. Deliverables. Excluding any property that constitutes Outside Property, any Deliverables shall be the sole property of Customer upon Customer’s payment in full of all associated Professional Services fees. GetMilitaryApproved shall execute and, at Customer’s written request, require its personnel to execute any document that may be necessary or desirable to establish or perfect Customer’s rights to the ownership of such Deliverables. For purposes of this Agreement, “Outside Property” means any and all technology and information, methodologies, data, designs, ideas, concepts, know- how, techniques, user-interfaces, templates, documentation, software, hardware, modules, development tools and other tangible or intangible technical material or information that GetMilitaryApproved possesses or owns prior to the commencement of Professional Services or which it develops independent of any activities governed by this Agreement, and any derivatives, modifications or enhancements made to any such property. Outside Property shall also include any enhancements, modifications or derivatives made by GetMilitaryApproved to the Outside Property while performing Professional Services hereunder, and any software, modules, routines or algorithms which are developed by GetMilitaryApproved during the term in providing the Professional Services to Customer, provided such software, modules, routines or algorithms have general application to work performed by GetMilitaryApproved for its other customers and do not include any content that is specific to Customer or which, directly or indirectly, incorporate or disclose Customer’s Confidential Information.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">7.4. Outside Property License. To the extent that GetMilitaryApproved incorporates any Outside Property into any Deliverables, then GetMilitaryApproved hereby grants Customer</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">a. limited, royalty-free, non-exclusive, non-transferable (subject to Section 14.11), without right to sublicense, license to use such Outside Property delivered to Customer solely as necessary for and in conjunction with Customer's use of the Deliverables.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">8. CONFIDENTIALITY</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">8.1. Definition of Confidential Information. "Confidential Information" means all confidential or proprietary information of a party ("Disclosing Party") disclosed to the other party ("Receiving Party"), whether orally or in writing that is designated as confidential or reasonably should be understood to be confidential given the nature of information and the circumstances of disclosure. Without limiting the coverage of these confidentiality obligations, the parties acknowledge and agree that Confidential Information of each party shall include the terms and conditions of this Agreement (including pricing and other terms set forth in all Order Forms and/or SOWs hereunder), related benchmark or similar test results, other technology and technical information, security information, security audit reports, and business and marketing plans, except that GetMilitaryApproved may reference and use Customer’s name, logos and the nature of the Services provided hereunder in GetMilitaryApproved’ s business development and marketing efforts.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">8.2. Exceptions. Confidential Information shall not include information that (i) is or becomes publicly available without a breach of any obligation owed to the Disclosing Party, (ii) is already known to the Receiving Party at the time of its disclosure by the Disclosing Party, without a breach of any obligation owed to the Disclosing Party, (iii) following its disclosure to the Receiving Party, is received by the Receiving Party from a third party without breach of any obligation owed to Disclosing Party, or (iv) is independently developed by Receiving Party without reference to or use of the Disclosing Party’s Confidential Information.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">8.3. Protection of Confidential Information. The Receiving Party shall use the same degree of care used to protect the confidentiality of its own Confidential Information of like kind (but in no event less than reasonable care), and, except with Disclosing Party’s written consent, shall (i) not use any Confidential Information of Disclosing Party for any purpose outside the scope of this Agreement and (ii) limit access to Confidential Information of Disclosing Party to those of its and its Affiliates’ employees, contractors and agents who need such access for purposes consistent with this Agreement and who have a duty or obligation of confidentiality no less stringent than that set forth herein.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">8.4. Compelled Disclosure. The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent required by applicable law, regulation or legal process, provided that the Receiving Party (i) provides prompt written notice to the extent legally permitted, (ii) provides reasonable assistance, at Disclosing Party's cost, in the event the Disclosing Party wishes to oppose the disclosure, and (iii) limits disclosure to that required by law, regulation or legal process.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">9. REPRESENTATIONS, WARRANTIES AND DISCLAIMERS</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">9.1. GetMilitaryApproved Representations & Warranties. GetMilitaryApproved represents and warrants that (i) GetMilitaryApproved has the legal authority to enter into this Agreement, (ii) the Subscription Services will materially conform with the relevant Documentation, (iii) the functionality and security of the Subscription Services will not be materially decreased during a Subscription Term, and (iv) Professional Services will be performed in a competent and workmanlike manner consistent with generally accepted industry standards.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">9.2. Remedies. For any failure of any Subscription Services or Professional Services, as applicable, to conform to their respective warranties, GetMilitaryApproved liability and Customer’s sole and exclusive remedy shall be for GetMilitaryApproved, in the case of a breach of the warranty set forth in Section 9.1 (ii), (iii), and/or (iv), to use commercially reasonable efforts to correct such failure; or, in the case of a breach of the warranty set forth in Section 9.1 (iv) to re-perform the affected Professional Services. If the foregoing remedies are not commercially practicable, GetMilitaryApproved may, in its sole discretion, terminate the applicable Order or SOW upon providing Customer with written notice thereof, and, as Customer’s sole and exclusive remedy, refund to Customer</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">(a) in the case of breach of the warranty set forth in Section 9.1(ii) or (iii), any Subscription Services fees paid by Customer with respect to the unexpired portion of the current Subscription Term for the non-conforming Subscription Services; or (b) in the case of breach of the warranty set forth in Section 9.1(iv), any fees paid by Customer for the portion of Professional Services giving rise to the breach.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">9.3. Customer Representations & Warranties. Customer represents and warrants that (i) it has the legal authority to enter into this Agreement, and (ii) it will use the Services in accordance with the terms and conditions set forth in this Agreement and in compliance with all applicable laws, rules and regulations.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">9.4. Disclaimer. EXCEPT AS EXPRESSLY PROVIDED HEREIN, GetMilitaryApproved MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, ORAL OR WRITTEN, STATUTORY OR OTHERWISE, AND GetMilitaryApproved HEREBY DISCLAIMS ALL IMPLIED WARRANTIES AND CONDITIONS, INCLUDING, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE, OR ANY WARRANTY WITH RESPECT TO THE QUALITY, PERFORMANCE, ACCURACY OR FUNCTIONALITY OF THE SERVICES OR THAT
                                        THE SERVICES ARE OR WILL BE ERROR FREE OR WILL ACCOMPLISH ANY PARTICULAR RESULT.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">10. MUTUAL INDEMNIFICATION</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">10.1. Indemnification by GetMilitaryApproved. GetMilitaryApproved shall indemnify, defend and hold Customer harmless from and against any judgments, settlements, costs and fees reasonably incurred (including reasonable attorney’s fees) resulting from any claim, demand, suit, or proceeding made or brought against Customer by a third party alleging that the use of the Subscription Services hereunder infringes or misappropriates the valid intellectual property rights of a third party (a "Claim Against Customer"); provided that Customer</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">a) Promptly gives GetMilitaryApproved written notice of the Claim against Customer;(b) Gives GetMilitaryApproved sole control of the defense and settlement of the Claim against Customer (provided that GetMilitaryApproved may not settle any Claim against Customer unless the settlement unconditionally releases Customer of all liability); and (c) provides to GetMilitaryApproved all reasonable assistance, at GetMilitaryApproved expense. In the event of a Claim Against Customer, or if GetMilitaryApproved reasonably believes the Subscription Services may infringe or misappropriate, GetMilitaryApproved may in GetMilitaryApproved sole discretion and at no cost to Customer (i) modify the Subscription Services so that they no longer infringe or misappropriate, without breaching GetMilitaryApproved warranties hereunder, (ii) obtain a license for Customer’s continued use of Subscription Services in accordance with this Agreement, or (iii) terminate Customer’s subscriptions for such Subscription Services and refund to Customer any prepaid fees covering the remainder of the term of such subscriptions after the effective date of termination. Notwithstanding the foregoing, GetMilitaryApproved shall have no obligation to indemnify, defend, or hold Customer harmless from any Claim Against Customer to the extent it arises from (i) Customer Data or Customer Applications, (ii) use by Customer after notice by GetMilitaryApproved to discontinue use of all or a portion of the Subscription Services, (iii) use of Services by Customer in combination with equipment or software not supplied by GetMilitaryApproved where the Service itself would not be infringing, (iv) or Customer’s breach of this Agreement.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">10.2. Indemnification by Customer. Customer shall indemnify, defend and hold GetMilitaryApproved harmless from and against any judgments, settlements, costs and fees reasonably incurred (including reasonable attorney’s fees) resulting from any claim, demand, suit or proceeding made or brought against GetMilitaryApproved by a third party alleging that Customer Data or Customer Application violates applicable law or a third party’s rights (a "Claim Against GetMilitaryApproved"); provided that GetMilitaryApproved (a) promptly gives Customer written notice of the Claim Against GetMilitaryApproved; (b) gives Customer sole control of the defense and settlement of the Claim Against GetMilitaryApproved (provided that Customer may not settle any Claim Against GetMilitaryApproved unless the settlement unconditionally releases GetMilitaryApproved of all liability); and (c) provides to Customer all reasonable assistance, at Customer’s expense. </p><br />
                                        <p className="m-0 text-sm-center text-xs-center">10.3. Exclusive Remedy. This Section 10 states the indemnifying party’s sole liability to, and the indemnified party’s exclusive remedy against, the other party for any type of claim described in this Section.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">11. LIMITATION OF LIABILITY</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">11.1. Limitation of Liability. OTHER THAN EACH PARTY’S OBLIGATIONS SET FORTH IN SECTION 10 (MUTUAL INDEMNIFICATION), NEITHER PARTY'S TOTAL AGGREGATE LIABILITY RELATING TO THIS AGREEMENT (WHETHER IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY) SHALL EXCEED THE AMOUNT PAID OR PAYABLE BY CUSTOMER FOR THOSE SERVICES GIVING RISE TO SUCH CLAIM UNDER THE APPLICABLE ORDER FORM AND/OR SOW IN THE 12 MONTHS PRECEDING THE APPLICABLE INCIDENT.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">11.2. Exclusion of Consequential and Related Damages. NEITHER PARTY SHALL HAVE ANY LIABILITY TO THE OTHER PARTY FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">12. TERM AND TERMINATION</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">12.1. Term of Agreement. This Agreement commences on the Effective Date and continues until otherwise terminated, by written agreement of the parties, in accordance with Section 12.3 or upon the expiration of the last Subscription Term or renewal thereof.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">12.2. Renewal of Subscription Services. Except as otherwise specified in the applicable Order, the Subscription Services shall automatically renew for successive one-year periods, unless and until terminated by either party in accordance herewith or unless either party provides written notice of non-renewal to the other party at least 30 days prior to the end of the then-current Subscription Term. GetMilitaryApproved may increase pricing applicable to the renewal of any then-current Subscription Term by providing Customer with notice thereof, including by email, at least 45 days prior to the end of such term.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">12.3. Termination. A party may terminate this Agreement (or, at such party’s option, the individual Order Forms or SOWs affected by the applicable breach), for cause (i) upon 30 days written notice to the other party of a material breach if such breach remains uncured at the expiration of such same 30 day period, or (ii) automatically if the other party becomes the subject of a petition in bankruptcy or other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors. Upon termination of an Order or SOW for cause by Customer and upon Customer’s written request, GetMilitaryApproved shall refund, on a pro rata basis, any fees paid thereunder that cover the remainder of the applicable Subscription Term after the effective date of termination. Upon termination of an Order or SOW for cause by GetMilitaryApproved, all amounts owed by Customer thereunder shall become due and payable. In no event shall any termination relieve Customer of the obligation to pay all fees payable to GetMilitaryApproved for the period prior to the effective date of termination.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">12.4. Data Portability and Deletion. Upon request made by Customer within 7 days of termination or expiration of the Subscription Services, GetMilitaryApproved will make Customer Data and Customer Applications available to Customer for export or download as provided in the Documentation. At the end of such 7 day period, GetMilitaryApproved will delete or otherwise render inaccessible any Customer Data and Customer Applications, unless legally prohibited. GetMilitaryApproved has no obligation to retain the Customer Data for Customer purposes after this 7 day post termination period.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">12.5. Survival. Section 7 (Proprietary Rights), 8 (Confidentiality), 9.4 (Disclaimer), 10 (Mutual Indemnification), 11 (Limitation of Liability), 12.4 (Refund upon Termination), 13 (Notices, Governing Law and Jurisdiction) and 14 (General Provisions) and any other rights and obligations of the parties hereunder that by their nature are reasonably intended to survive termination or expiration, shall survive any termination or expiration of this Agreement.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">13. NOTICES, GOVERNING LAW AND JURISDICTION</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">13.1. Manner of Giving Notice. Except as otherwise specified in this Agreement, all legal notices of default, breach or termination (“Legal Notices”) hereunder shall be in writing and shall be deemed to have been given upon (i) personal delivery, (ii) the fifth business day after being sent by certified mail return receipt requested, or (iii) the first business day after sending by a generally recognized international guaranteed overnight delivery service. Each party shall send all Legal Notices to the other party at the address set forth in the applicable Order Form or SOW, as such party may update such information from time to time, with, in the case of notices sent by Customer, a copy sent to the GetMilitaryApproved Legal Department at the address first set forth above. Billing-related notices to Customer will be addressed to the relevant billing contact designated by Customer on the applicable Order.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">13.2. Governing Law and Jurisdiction. If Customer is entering into this Agreement from the UK or a European Union member country, then this Agreement is governed by the Georgian laws and subject to the exclusive jurisdiction of the courts of Georgia. Each party consents to the jurisdiction of such court in any such civil action or legal proceeding and waives any objection to the laying of venue of any such civil action or legal proceeding in such court. Notwithstanding the foregoing, the parties acknowledge that any unauthorized disclosure of Confidential Information or any actual or alleged infringement of such party’s or third party’s intellectual property rights might cause the other party to suffer irreparable harm for which damages would be an inadequate remedy and that, in such event, the aggrieved party may seek, in addition to any other available remedies, injunctive and other equitable relief in any state, federal, or national court of competent jurisdiction, without bond and without the necessity of showing actual monetary damages. The United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transactions Act do not apply to the Agreement.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">13.3. Waiver of Jury Trial. Each party hereby waives any right to jury trial in connection with any action or litigation in any way arising out of or related to this Agreement.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">14. GENERAL PROVISIONS</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">14.1. Import and Export Compliance. Each party shall comply with all applicable import, re-import, export, and re-export control laws, treaties, agreements, and regulations. Export controls may include, but are not limited to, those of the Export Administration Regulations of the U.S. Department of Commerce (EAR), the Department of State International Traffic in Arms Regulations (ITAR), and country-specific economic sanctions programs implemented by the Office of Foreign Assets Control (OFAC), which may restrict or require licenses for the export of Items from the United States and their re-export from other countries. Each party represents that it is not named on any U.S. government denied-party list. Customer shall not permit users to access or use Services in a U.S.-embargoed country or in violation of any U.S. export law or regulation.</p><br />
                                        <p className="m-0 text-sm-center text-xs-center">14.2. Anti-Corruption. Neither party has received or been offered any illegal or improper bribe, kickback, payment, gift, or thing of value from any of the other party’s employees or agents in connection with this Agreement. If a party learns of any violation of the above restriction, such party will use reasonable efforts to promptly notify the other party. </p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">14.3. Federal Government End Use Provisions (only applicable for the U.S.). If the Services are being or have been acquired with U.S. Federal Government funds, or Customer is an agency, department, or other entity of the United States Government, the use, duplication, reproduction, release, modification, disclosure or transfer of the Services, or any related documentation of any kind, including technical data, manuals or GetMilitaryApproved Property is a "commercial item," as that term is defined in 48 C.F.R. 2.101 (Oct. 1995), consisting of "commercial computer software" and "commercial computer software documentation," as such terms are used in 48 C.F.R. 12.212 (Sept. 1995), as applicable. Consistent with 48 C.F.R. 12.212 and 48 C.F.R. 227.7202-1 through 227.7202-4 (June 1995), all U.S. Government End Users acquire the software and Services with only those rights set forth in this Agreement and any amendment hereto.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">14.4. Subscription Service Analyses. GetMilitaryApproved may (i) compile statistical and other information related to the performance, operation and use of the Subscription Services, and (ii) use, and share data from the Subscription Services environment in aggregated form for security and operations management, to create statistical analyses, and for research and development purposes (clauses i and ii are collectively referred to as ‘Subscription Service Analyses”). Subscription Service Analyses will not incorporate any information, including Customer Data, in a form that could serve to identify Customer or an individual. GetMilitaryApproved retains all intellectual property rights in Subscription Service Analyses.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">14.5. Relationship of the Parties. The parties are independent contractors. This Agreement does not create a partnership, franchise, joint venture, agency, and fiduciary or employment relationship between the parties.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">14.6. Non-Solicitation. Customer agrees that during the term of each Order Form and/or SOW and for twelve (12) months thereafter, it will not recruit or otherwise solicit for employment any person employed by GetMilitaryApproved who participated in the performance of Services under the applicable Order Form and/or SOW. Nothing in this clause shall be construed to prohibit individual GetMilitaryApproved employees from responding to public employment advertisements, postings or job fairs of Customer, provided such response is not prompted by Customer intentionally circumventing the restrictions of this Section.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">14.7. No Third-Party Beneficiaries. There are no third-party beneficiaries to this Agreement.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">14.8. Waiver. No failure or delay by either party in exercising any right under this Agreement shall constitute a waiver of that right.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">14.9. Force Majeure. Neither party shall be liable under this Agreement for delays or failures to perform the Services or this Agreement due to causes beyond its reasonable control. Such delays include, but are not limited to, fire, natural catastrophe, government legislation, acts, orders, or regulation, strikes or labor difficulties, to the extent not occasioned by the fault or negligence of the delayed party. Any such excuse for delay shall last only as long as the event remains beyond the reasonable control of the delayed party. The delayed party shall use its best efforts to minimize the delays caused by any such event beyond its reasonable control. If the force majeure event continues for more than thirty (30) calendar days, then either party may terminate the Agreement upon written notice to the other party.</p> <br />
                                        <p className="m-0 text-sm-center text-xs-center">14.10. Severability. If any provision of this Agreement is held by a court of competent jurisdiction to be contrary to law, the provision shall be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of this Agreement shall remain in effect.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">14.11. Assignment. Neither party may assign its rights and obligations hereunder, either in whole or in part, whether by operation of law or otherwise, without the prior written consent of the other party. Notwithstanding the foregoing, either party may assign this Agreement in its entirety (including all Order Forms and SOWs), without consent of the other party, to its Affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets not involving a direct competitor of the other party. Subject to the foregoing, this Agreement shall bind and inure to the benefit of the parties, their respective successors and permitted assigns.</p>
                                        <br />
                                        <p className="m-0 text-sm-center text-xs-center">14.12. Entire Agreement. This Agreement constitutes the entire agreement between the parties as it relates to the subject matter and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning or relating to the same. Except as otherwise provided herein, no modification, amendment, or waiver of any provision of this Agreement shall be effective unless in writing and signed by both parties. To the extent of any conflict or inconsistency between the provisions of this Agreement, the Documentation, any Order Form or SOW, the terms of such Order Form or SOW shall prevail. Notwithstanding any language to the contrary therein, no terms or conditions stated in a PO, payment system, other order documentation or otherwise (excluding Order Forms and/or SOWs) shall be incorporated into or form any part of this Agreement, and all such terms or conditions shall be null and void.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterMain />
            </div>
        );
    }
}

export default SubscriptionAgreement;